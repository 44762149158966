import { ConditionsWrapper } from "./conditions/conditions-wrapper";

export interface IBackground {
  color: string | null;
  image: string | null;
  imageUrl?: URL;
  segmentClass: Set<string>;
}

const reJavaScriptMimeType = /^(?:application\/(x-)?(java|ecma)script)|(text\/((j|live|((x-)?(java|ecma)))script)|(javascript1.[0-5]))$/;
const isJavaScriptMimeType = (node: HTMLScriptElement) => {
  return !node.type || reJavaScriptMimeType.test(node.type);
}

function isInlineScript(node: Node): node is HTMLScriptElement {
  return (
    node.nodeType === Node.ELEMENT_NODE &&
    (node as Element).tagName === "SCRIPT" &&
    !(node as HTMLScriptElement).src &&
    !!(node as HTMLScriptElement).textContent &&
    isJavaScriptMimeType(node as HTMLScriptElement)
  );
}

function isAfter(node1: Node, node2: Node): boolean {
  const pos = node2.compareDocumentPosition(node1);
  return !((pos & Node.DOCUMENT_POSITION_CONTAINS) ||
          !(pos & Node.DOCUMENT_POSITION_FOLLOWING));
}

export default class FragmentWrapper {
  lexiaDom: Element;
  defaultLinkDom: HTMLLinkElement | null;
  protected document: Document;
  private _conditions?: ConditionsWrapper;
  hasChanges = false;
  static controlledSegmentClasses = new Set<string>();

  constructor(html: string, protected baseUrl?: URL) {
    this.document = new DOMParser().parseFromString(html, "text/html");
    this.lexiaDom = this._getLexiaDomFromFragmentDom(this.document);
    this.defaultLinkDom = null;
  }

  get conditions(): ConditionsWrapper {
    if (this._conditions === undefined) {
      const scripts = Array.from(this.document.body.querySelectorAll("script"))
        .filter((script) => isInlineScript(script) && isAfter(script, this.lexiaDom));
      this._conditions = new ConditionsWrapper(scripts, this.document.body as HTMLBodyElement);
    }
    return this._conditions;
  }

  getDocumentHtml(): string {
    if (this._conditions) {
      this._conditions.save();
    }
    return "<!doctype html>\n" + this.document.documentElement.outerHTML;
  }

  setLexiaHtml(lexiaHTML: string): void {
    this.lexiaDom.innerHTML = lexiaHTML;
  }

  getLexiaHtml(): string {
    return this.lexiaDom.innerHTML;
  }

  getBackground(): IBackground {
    const color = this.document.body.getAttribute("bgcolor");
    const image = this.document.body.getAttribute("background");
    const imageUrl = image ? new URL(image, this.baseUrl) : undefined;
    const segmentClass = new Set(Array.from(this.lexiaDom.classList).map(c => c.toLowerCase()));
    segmentClass.delete("ui");
    segmentClass.delete("segment");
    return { color, image, segmentClass, imageUrl };
  }

  setBackground(background?: IBackground) {
    if (background?.color) this.document.body.setAttribute("bgcolor", background.color);
    else this.document.body.removeAttribute("bgcolor");

    if (background?.image) this.document.body.setAttribute("background", background.image);
    else this.document.body.removeAttribute("background");

    if (background) {
      for (const cls of FragmentWrapper.controlledSegmentClasses) {
        this.lexiaDom.classList.toggle(cls, background.segmentClass.has(cls));
      }
    }

    this.hasChanges = true;
  }

  setDefaultLinkHref(href: string): void {
    this._ensureDefaultLinkNodeExists();

    if (!this.defaultLinkDom) return;
    // TODO: if fragment wrapper were merged with lexia, we could calculate relative links here
    this.defaultLinkDom.href = href;
  }

  addConditionalLink(script: string, targets: [string, ...string[]]): string {
    const ids = this._saveConditionalLinkTargets(targets);
    const linkId = this.assingId();
    this.conditions.add(linkId, script, ids);
    return linkId;
  }

  assingId(): string {
    let nextId = 0, id: string;
    do {
      id = "id" + nextId++;
    } while (this.document.getElementById(id))
    return id;
  }

  private _saveConditionalLinkTargets(hrefs: string[]): string[] {
    const missingHrefs = new Set(hrefs);
    const ids = new Map<string, string>();
    this.document
      .querySelectorAll<HTMLLinkElement>('link[rel=conditional][id]')
      .forEach((link) => {
        missingHrefs.delete(link.href);
        ids.set(link.href, link.id);
      });

    let nextId = 0;
    const head = this.document.head;
    missingHrefs.forEach(href => {
      const link: HTMLLinkElement = this.document.createElement('link');
      link.rel = "conditional";
      link.href = href;

      let id: string;
      do {
        id = "id" + nextId++;
      } while (this.document.getElementById(id));
      link.id = id;
      ids.set(href, id);

      head.appendChild(link);
    });

    return hrefs.map(href => ids.get(href)!);
  }

  private _ensureDefaultLinkNodeExists(): void {
    if (this.defaultLinkDom == null) {
      this.defaultLinkDom = this.document.querySelector("link[rel=next]");

      if (this.defaultLinkDom == null) {
        this.defaultLinkDom = document.createElement("link");
        this.defaultLinkDom.rel = "next";
        this.document.head.appendChild(this.defaultLinkDom);
      }
    }
  }

  private _getLexiaDomFromFragmentDom(document: Document): Element {
    const tagsToSearch = ["main", "article", "section", "tw-passagedata", "body"];

    for (const tagName of tagsToSearch) {
      let foundTagsArray = document.getElementsByTagName(tagName);
      if (foundTagsArray.length > 0) return foundTagsArray[0];
    }

    throw new Error("No lexia found under following tags:" + tagsToSearch);
  }
}
