const stringify = (s: string): string => JSON.stringify(s).replace(/\\"/g, "\\x22");
const parseString = (s: string): string => {
  const r = JSON.parse(s.replace(/\\x22/g, '\\"'));
  if (typeof r !== "string") throw new Error("Invalid string");
  return r;
}

export class ConditionsWrapper {
  protected containerScript?: HTMLScriptElement;
  protected conditions: {
    id: string;
    script: string;
    targets: string[];
  }[] = [];

  constructor(scripts: HTMLScriptElement[], protected body: HTMLBodyElement) {
    for (const script of scripts) {
      if (this.parse(script.textContent || "")) {
        this.containerScript = script;
      }
    }
  }

  save(): void {
    if (!this.containerScript) {
      this.containerScript = document.createElement("script");
      this.containerScript.type = "text/javascript";
      this.body.appendChild(this.containerScript);
    }

    const lines = [];
    for (const condition of this.conditions) {
      const id = stringify(condition.id);
      const targets = condition.targets.map(id => stringify(id)).join(",\n\t\t");
      const line = `\tc(${id}, ${condition.script}, [\n\t\t${targets}\n\t]);\n`;
      lines.push(line);
    }
    this.containerScript.textContent = "\n\nInkah((c, v) => {\n" + lines.join("") + "});\n\n";
  }

  add(anchorElementId: string, conditionScript: string, linkElementsIds: string[]) {
    const existing = this.conditions.find(condition => condition.id === anchorElementId);
    if (existing) {
      existing.script = conditionScript;
      existing.targets = linkElementsIds;
    } else {
      this.conditions.push({
        id: anchorElementId,
        script: conditionScript,
        targets: linkElementsIds
      });
    }
  }

  // TODO: ?
  /* removeCondition(anchorElementId: string): void {
    this.conditions = this.conditions.filter(condition => condition.id !== anchorElementId);
  } */

  protected parse(s: string): boolean {
    s = s.trim();
    if (!s.startsWith("Inkah((c, v) => {") || !s.endsWith("});")) return false;

    try {
      const lines = s.slice(17, -3).trim().split(/\s*;\s*\n\s*/).filter(line => !!line);
      for (const line of lines) {
        const match = /^c\(("[^"]+"),\s*(.*), \[([^\]]+)\]\)$/.exec(line);
        if (!match) {
          console.error("Invalid script:", line);
          return false;
        }
        const id = parseString(match[1]);
        const script = match[2];
        const targets = match[3].trim().split(/\s*,\s*/).map(id => parseString(id));

        this.conditions.push({ id, script, targets });
      }
    } catch (e) {
      console.error("Error parsing script:", e);
      return false;
    }

    return true;
  }
}
