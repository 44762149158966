/* eslint-disable */
import { Component } from "react";
import Layout from "../../../components/layout/Layout";
import FragmentWrapper from "../../../story-api/fragment-wrapper";

const test_fragment = (
  "<!doctype html>\n" +
  '<html lang="en">\n' +
  "    <head>\n" +
  "        <!-- ... -->\n" +
  '        <meta charset="UTF-8" />\n' +
  '        <meta name="viewport" content="width=device-width, initial-scale=1.0" />\n' +
  '        <link href="styles.css" rel="stylesheet">\n' +
  "\n" +
  '        <link href="https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@100;200;300;400;500;600;700;800;900&display=swap" rel="stylesheet">\n' +
  '        <link href="https://fonts.googleapis.com/css2?family=Roboto+Mono:ital,wght@0,100;0,200;0,300;0,400;0,500;1,100;1,200;1,300;1,400;1,500&display=swap" rel="stylesheet">\n' +
  "\n" +
  "    </head>\n" +
  "\n" +
  '    <body id="there">\n' +
  "\t\n" +
  "\n" +
  '<div class="container md:mx-auto  md:px-8 max-w-screen-md \n' +
  '             px-2"> \n' +
  "\n" +
  '            <div class="ramkaGlowna border-gray-200 border-2 pb-4 mt-14 rounded-xl"> \n' +
  '                <div class="sygnatura text-gray-300 -m-4 text-center text-lg  tracking-widest"> Michael Joyce: Twilight. A Symphony</div> \n' +
  "\n" +
  "                <!-- main frame   -->\n" +
  '                <div id="container" class="relative bg-white bg-opacity-90 rounded-xl \n' +
  "                     md:pl-20 md:pr-18 md:pb-5 md:mb-4 md:mt-12 md:mx-2 md:pt-0\n" +
  '                     pl-4 pr-3 pb-4 mb-3 mt-12 mx-2 pt-0" > <!-- text screen and mobile settings: margin padding etc -->\n' +
  "                    <!-- start content  -->\n" +
  "\n" +
  "\n" +
  "                    <div>\n" +
  '                        <!-- start content --><section class="leksja font-serif text-gray-900 text-base tracking-wide text-left md:leading-7">\t  \n' +
  "\n" +
  "                            <!-- 1. MICHAŁ > TITLE CONTENT -->\n" +
  "\n" +
  "\n" +
  "\n" +
  '                            <h1 class="text-zmrokGRAY-lighter font-sans tracking-widest mb-6 pb-0 text-2xl pt-3 -ml-2.5">/autumnal walkings</h1>\n' +
  "\n" +
  "\n" +
  "\n" +
  "\n" +
  "\n" +
  '                            <!--   <section class="leksja">-->\n' +
  '                            <section class="leksja">\n' +
  ' <p>Two days ago two ducks swam the thin rapids beneath the foot bridge along the Kill, the bridge I think of as the place where<a class=\'textLink\' title="prayer" rev_title="protector" href="protector.html"> my son and I made our pact </a>against anger when we stopped here this past summer. </p>\n' +
  " <p>At my footfall on the bridge they splashed ahead into the small pool, gliding and widening it in doing so: mallards. Later the storm lays down a large tree along the drive in front of the house, the crack and wheezing sigh followed by the easing thud in the persistent rain and constant wind.</p>\n" +
  " <p>Yesterday morning by the gravel path around the shore of Sunset Lake (which darkens and seems deeper now than its jade-scummed late August shallows) three crows sat in profile just beyond the bridge where we made our pact. Returning last night three drummers beat talking rhythms on the opposite shore as they sat on a bench beside a lone fisherman. Whether their drumming was prompted by the new age lunacy of Iron John fervor or whether it was actual, africana or indian, it sounded rich and melancholy in the pinkish twilight of the still lake as I made my way past the red barn where once we sheltered from the rain, across the stone wall, and home.</p>\n" +
  " <p>The Heron gone for days now. </p>\n" +
  " <p>“It’s just the ice I don’t like,” said the woman cashier, explaining about winter.</p>\n" +
  " <p>After eating good curry, a moment of euphoria, followed by the feeling of loss, missing my boys this day of bridges and men drumming. This morning wake late to cold, clear sunlight the day after steam begins to fill radiators in this old house making it hard to breathe or sleep. </p>\n" +
  " <p>On the way back from my walk, the heron not gone but doubled, a mirror bird in the mirror pond beyond. </p>\n" +
  " <p>On the walk, pumpkin furred caterpillar crosses my path where yesterday the striped wooly caterpillar also crossed.</p>\n" +
  " <p>Hornets ceasing on the path, their wings heavy and dull with cold.</p>\n" +
  " <p>A lone dove flies off into the wood.</p>\n" +
  " <p>As the girl who keeps the ecological station drives off in her brown Toyota wagon, bundled in a dark sweatsuit.</p>\n" +
  " <p>The African woman who lives at the ramshackle student house across the lane keeps a blanket spread out in the sun on her lawn, day and night; a blue on blue print of stars and beasts (whales or antelopes I cannot tell from the lane as I walk past). It may be a beach blanket, I don’t know, but she sits there days, her village as much as this my desk, my screen, mine.</p>\n" +
  "</section>\n" +
  "\n" +
  "                            <!--  </section> -->\n" +
  "\n" +
  "                    </div>\n" +
  "\n" +
  "\n" +
  "\n" +
  "\n" +
  "                </div>\n" +
  "                <!-- END TEXT -->\n" +
  "\n" +
  "\n" +
  "                <!-- toolbar start  -->\n" +
  '                <div class="container">\n' +
  '                    <ul class="flex justify-center space-x-8">\n' +
  "\n" +
  "                        <!-- history button and icon -->\n" +
  '                        <li class="historia">\n' +
  "                            <!-- button history -->\n" +
  '                            <button class="buttonHistoria"\n' +
  '                                    onclick="return ShowHistoryWindow();">\n' +
  '                                <a title="Reading history" id="history_link" class="nav-link"\n' +
  '                                   data-toggle="tailwind-modal" data-target="#tailwind-modal"> <!-- IKONA HISTORIA -->\n' +
  '                                    <svg class="h-7 w-7 opacity-90 text-white"   width="\n' +
  '                                         24" height="24" viewBox="0 0 24 24" stroke-width="2"\n' +
  '                                         stroke="currentColor" fill="none" stroke-linecap="round"\n' +
  '                                         stroke-linejoin="round">  <path stroke="none"\n' +
  '                                         d="M0 0h24v24H0z" />  <polyline points="12 8 12 12 14 14" />  <path\n' +
  '                                         d="M3.05 11a9 9 0 1 1 .5 4m-.5 5v-5h5" />\n' +
  "                                    </svg>\n" +
  "                                </a>\n" +
  "                            </button> \n" +
  "\n" +
  "                            <!-- modal history -->\n" +
  '                            <div class="hidden modal flex flex-col absolute inset-0 bg-gray-900 bg-opacity-60 justify-center items-center" id="HISTORY_WINDOW_DIV">\n' +
  "\n" +
  "\n" +
  "\n" +
  '                                <div class="bg-zmrokMODALlighter w-2/3 md:w-1/2 lg:w-1/3 min-width:740px py-2 px-3 rounded-xl shadow-xl z-10 opacity-95">\n' +
  "\n" +
  "\n" +
  '                                    <div class="z-10 ramka relative border-zmrokMODAL-dark border-2 pb-4 m-0 rounded-xl flex flex-col">\n' +
  '                                        <div class="absolute top-0 right-0 mt-4 mr-4">\n' +
  '                                            <button   onclick="return HideHistoryWindow();" class="rounded-full h-12 w-12 px-3 py-1 border-2 border-gray-800 bg-zmrokMODALlighter text-gray-800 hover:bg-zmrokGRAY-lighter uppercase text-xs font-modal-sans">\n' +
  "                                                <svg\n" +
  '                                                    class="close-modal cursor-pointer"\n' +
  '                                                    fill="currentColor" viewBox="0 0 20 20">\n' +
  '                                                <path fill-rule="evenodd"\n' +
  '                                                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"\n' +
  '                                                      clip-rule="evenodd"></path>\n' +
  "                                                </svg>\n" +
  "                                            </button>\n" +
  "                                        </div>\n" +
  "\n" +
  "\n" +
  "\n" +
  "\n" +
  "\n" +
  '                                        <div class="z-1 text-center">\n' +
  '                                            <h4 class="inline-block text-lg font-sans\n' +
  '                                                text-zmrokGRAY-dark italic font-extrabold mt-8 pb-5 border-b-2 border-solid border-zmrokMODAL-dark">\n' +
  '                                                <span class="font-modal-sans border-b-2 border-solid border-zmrokMODAL-dark pb-4 tracking-wide text-gray-900">\n' +
  "                                                    OUR STORY SO FAR</span></h4>\n" +
  '                                            <h5 class="font-modal-sans mt-8 capitalize">You have read:</h5>\n' +
  '                                            <h4 class="mt-0  text-sm font-modal-sans">\n' +
  '                                                <samp class="font-modal-sans historyCount inline-block normal-case">50</samp>\n' +
  "                                                out of\n" +
  '                                                <samp class="font-modal-sans inline-block normal-case">329</samp>\n' +
  "                                                lexias\n" +
  "                                            </h4>\n" +
  '                                            <div class="text-gray-700 mt-4">***</div>\n' +
  '                                            <p class="font-modal-sans capitalize mt-1 text-lg">Last visited:</p>\n' +
  "                                        </div>\n" +
  "\n" +
  '                                        <div class="mt-0 p-0 text-sm text-center italic font-modal-sans" id="HISTORY_WINDOW_LIST">\n' +
  '                                            <ul class="font-modal-sans">\n' +
  "                                                <li>Odwiedzony segment 1</li>\n" +
  "                                                <li>Odwiedzony segment 2</li>\n" +
  "                                            </ul>\n" +
  "                                        </div>\n" +
  "\n" +
  '                                        <div class="mt-3 flex justify-center space-x-3">\n' +
  '                                            <button onclick="return ClearHistory();"\n' +
  '                                                    class="font-modal-sans px-3 py-1 border-2 border-double bg-zmrokMODALlighter border-zmrokNAVY-dark text-zmrokGRAY-dark hover:bg-zmrokGRAY-lighter rounded uppercase text-sm mt-6 tracking-wider">Clear\n' +
  "                                                History\n" +
  "                                            </button>\n" +
  "                                        </div>\n" +
  "                                    </div>   \n" +
  "                                </div> <!-- RAMKA -->\n" +
  "                            </div> \n" +
  "                            <!--KONIEC MODALA HISTORIA: -->\n" +
  "                        </li>\n" +
  "\n" +
  "                        <!-- 4B. MAPA  -->\n" +
  '                        <li class="mapa">\n' +
  "                            <!-- BUTTON MAPA: -->\n" +
  '                            <button class="buttonMapa" id="idButtonMapa">\n' +
  '                                <a class="nav-link" data-toggle="tab"> <svg\n' +
  '                                        class="h-8 w-8 text-white  opacity-75 z-20" width="24"\n' +
  '                                        height="24" viewBox="0 0 24 24" stroke-width="2"\n' +
  '                                        stroke="currentColor" fill="none" stroke-linecap="round"\n' +
  '                                        stroke-linejoin="round">\n' +
  '                                    <path stroke="none" d="M0 0h24v24H0z" />\n' +
  '                                    <rect x="3" y="15" width="6" height="6" rx="2" />\n' +
  '                                    <rect x="15" y="15" width="6" height="6" rx="2" />\n' +
  '                                    <rect x="9" y="3" width="6" height="6" rx="2" />\n' +
  '                                    <path d="M6 15v-1a2 2 0 0 1 2 -2h8a2 2 0 0 1 2 2v1" />\n' +
  '                                    <line x1="12" y1="9" x2="12" y2="12" />\n' +
  "                                    </svg>\n" +
  "                                </a>\n" +
  "                            </button>\n" +
  "                        </li>\n" +
  "\n" +
  "                        <!-- 4C. LINKI  -->\n" +
  '                        <li class="linki">\n' +
  "                            <!-- 4D. POCZĄTEK MODALU  - BUTTON -->\n" +
  '                            <button class="show-modal">\n' +
  '                                <a title="Link report" onclick="return ShowLinkListWindow();"\n' +
  '                                   class="inki-nav" data-toggle="modal"> <!-- IKONA LINKI: --> <svg\n' +
  '                                        class="h-7 w-7 text-white opacity-75" width="24" height="24"\n' +
  '                                        viewBox="0 0 24 24" stroke-width="2" stroke="currentColor"\n' +
  '                                        fill="none" stroke-linecap="round" stroke-linejoin="round">  <path\n' +
  '                                        stroke="none" d="M0 0h24v24H0z" />  <path\n' +
  '                                        d="M10 14a3.5 3.5 0 0 0 5 0l4 -4a3.5 3.5 0 0 0 -5 -5l-.5 .5" />  <path\n' +
  '                                        d="M14 10a3.5 3.5 0 0 0 -5 0l-4 4a3.5 3.5 0 0 0 5 5l.5 -.5" /></svg>\n' +
  "                                </a>\n" +
  "                            </button> \n" +
  "                        </li>\n" +
  "\n" +
  "                        <!-- D4 DEFAULT: -->\n" +
  '                        <li class="default animate-pulse">\n' +
  "                            <!-- IKONA DEFAULT: --> \n" +
  '                            <!--  <svg class="h-8 w-8 text-white opacity-90" xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">\n' +
  '                            <path fill-rule="evenodd" d="M6.672 1.911a1 1 0 10-1.932.518l.259.966a1 1 0 001.932-.518l-.26-.966zM2.429 4.74a1 1 0 10-.517 1.932l.966.259a1 1 0 00.517-1.932l-.966-.26zm8.814-.569a1 1 0 00-1.415-1.414l-.707.707a1 1 0 101.415 1.415l.707-.708zm-7.071 7.072l.707-.707A1 1 0 003.465 9.12l-.708.707a1 1 0 001.415 1.415zm3.2-5.171a1 1 0 00-1.3 1.3l4 10a1 1 0 001.823.075l1.38-2.759 3.018 3.02a1 1 0 001.414-1.415l-3.019-3.02 2.76-1.379a1 1 0 00-.076-1.822l-10-4z" clip-rule="evenodd" />\n' +
  "                          </svg>--> \n" +
  '                            <a href="#" class="nav-link default-nav" data-toggle="tab">\n' +
  '                                <svg class="h-7 w-7 text-white opacity-90" xmlns="http://www.w3.org/2000/svg"  fill="none" viewBox="0 0 24 24" stroke="currentColor">\n' +
  '                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 15l-2 5L9 9l11 4-5 2zm0 0l5 5M7.188 2.239l.777 2.897M5.136 7.965l-2.898-.777M13.95 4.05l-2.122 2.122m-5.657 5.656l-2.12 2.122" />\n' +
  "                                </svg>\n" +
  '                                <!--<svg class="h-8 w-8 text-white opacity-90" xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">\n' +
  '                                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 11.5V14m0-2.5v-6a1.5 1.5 0 113 0m-3 6a1.5 1.5 0 00-3 0v2a7.5 7.5 0 0015 0v-5a1.5 1.5 0 00-3 0m-6-3V11m0-5.5v-1a1.5 1.5 0 013 0v1m0 0V11m0-5.5a1.5 1.5 0 013 0v3m0 0V11" />\n' +
  "                                </svg>--> \n" +
  "                            </a>\n" +
  "                        </li>\n" +
  "                    </ul>\n" +
  "                    <!-- END TOOLBAR:  -->\n" +
  "\n" +
  "\n" +
  "                    <!-- map modal start, with semi-transp background at full w and h  -->\n" +
  '                    <div id="MAP_WINDOW_DIV" class="hidden modal inset-0 bg-gray-800 bg-opacity-90\n' +
  '                         flex justify-center items-center ">\n' +
  "\n" +
  "\n" +
  '                        <div class="modal-content relative bg-zmrokMODALlighter w-2/3 md:w-2/3 lg:w-1/2 py-2 px-3 rounded-xl opacity-95">\n' +
  "                            <!-- TYTUŁ MAPS-->\n" +
  '                            <div class="text-center font-modal-sans">\n' +
  '    <h4 class="font-modal-sans text-gray-900 italic font-extrabold mt-8 pb-5 tracking-wide text-2xl">MAPS</h4>    \n' +
  "                            </div>\n" +
  "\n" +
  "\n" +
  "                            <!-- SVG ZAWIERAJĄCE HIERARCHY-->\n" +
  '                            <div id="MAP-HIERARCHY" class="tabcontent" style="height:92%">\n' +
  "                                <!--<h3>MAP-HIERARCHY</h3> !-->\n" +
  "\n" +
  '                                     <div class="visualNavigation flex">\n' +
  "\t\t\t\t\t\t\t\t\n" +
  '                                    <div class="flex mx-auto">\n' +
  '                                        <div><button id="HIER_GO_BACK_BTN" class="tablinks tabButtonInMapWindow font-modal-sans border-zmrokNAVY-dark text-zmrokGRAY-dark  uppercase tracking-wide text-sm mt-2 mb-2" \n' +
  '                                            onclick="hier_go_BACK()"><svg xmlns="http://www.w3.org/2000/svg" class="h-8 w-8 text-zmrokMODAL-Zoom" fill="none" viewBox="0 0 24 24" stroke="currentColor">\n' +
  '                                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11 15l-3-3m0 0l3-3m-3 3h8M3 12a9 9 0 1118 0 9 9 0 01-18 0z" />\n' +
  "                                              </svg></button></div>\n" +
  '                                        <div> <button id="HIER_GO_UP_BTN" class="tablinks tabButtonInMapWindow font-modal-sans border-zmrokNAVY-dark text-zmrokGRAY-dark uppercase tracking-wide text-sm mt-2 mb-2" \n' +
  '                                            onclick="hier_go_UP()"><svg xmlns="http://www.w3.org/2000/svg" class="h-8 w-8 text-zmrokMODAL-Zoom" fill="none" viewBox="0 0 24 24" stroke="currentColor">\n' +
  '                                              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 11l3-3m0 0l3 3m-3-3v8m0-13a9 9 0 110 18 9 9 0 010-18z" />\n' +
  "                                            </svg></button></div>\n" +
  "                                \n" +
  "                                      </div>\n" +
  "\n" +
  "                                \n" +
  "\n" +
  "                                \n" +
  "                                     <!-- PRZYCISK – idź w dół hierarchii MP zmienił na HIER_GO_DOWN_BTN\n" +
  "\n" +
  "\n" +
  '                                      <button id="HIER_GO_DOWN_BTN" class="tablinks tabButtonInMapWindow font-modal-sans border-zmrokNAVY-dark text-zmrokGRAY-dark hover:bg-zmrokGRAY-lighter uppercase tracking-wide text-sm mt-2 mb-2" \n' +
  '                                      onclick="hier_go_DOWN()"><svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-zmrokMODAL-dark" fill="none" viewBox="0 0 24 24" stroke="currentColor">\n' +
  '                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 11l3-3m0 0l3 3m-3-3v8m0-13a9 9 0 110 18 9 9 0 010-18z" />\n' +
  "                                      </svg></button>\n" +
  "\t\t\t\t\t\t\t\t\t  -->\n" +
  "                                    </div>\n" +
  "\n" +
  '                                                                <svg class="bg-zmrokMODALlighter w-full svg-with-hierarchy-or-inbound-outbound font-modal-sans italic tracking-wide text-twLexiaTitle" id="MAP_SVG_HIERARCHY"></svg>\n' +
  "\n" +
  "                            </div>\n" +
  "\n" +
  "\n" +
  "                            <!-- SVG ZAWIERAJĄCE IO-->\n" +
  '                            <div id="MAP-INBOUND-OUTBOUND" class="tabcontent hidden">\n' +
  "                                <!--  <h3>MAP-INBOUND-OUTBOUND</h3> !-->\n" +
  '                                <svg class="bg-zmrokMODALlighter w-full svg-with-hierarchy-or-inbound-outbound font-modal-sans italic tracking-wide text-twLexiaTitle" id="MAP_SVG"></svg>\n' +
  "                            </div>\n" +
  "\n" +
  "\n" +
  "\n" +
  "                            <!-- Close modal button -->\n" +
  '                            <div class="absolute top-0.5 right-0 flex justify-end items-center w-100 p-3">\n' +
  '                                <button class="rounded-full h-12 w-12 px-3 py-1 border-2 border-double bg-zmrokMODALlighter border-zmrokMODAL-dark text-zmrokGRAY-dark hover:bg-zmrokGRAY-lighter uppercase text-xsclose-modal"\n' +
  '                                        id="MAP_WINDOW_CLOSE_BUTTON">\n' +
  '                                    <svg class="close-modal cursor-pointer"\n' +
  '                                         fill="currentColor" viewBox="0 0 20 20">\n' +
  '                                    <path fill-rule="evenodd"\n' +
  '                                          d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"\n' +
  '                                          clip-rule="evenodd">\n' +
  "                                    </path>\n" +
  "                                    </svg>\n" +
  "                                </button>\n" +
  "                            </div> \n" +
  "\n" +
  "\n" +
  "                            <!-- Buttons to select map type-->\n" +
  '                            <div class="ramka bg-zmrokMODALlighter">\n' +
  '                                <div class="text-center font-modal-sans">\n' +
  "\n" +
  "\n" +
  '                                    <div class="mt-0 text-center justify-center">\n' +
  "                                        <button onclick=\"openMap(event, 'MAP-HIERARCHY')\"\n" +
  '                                                class="font-modal-sans px-3 py-1 border-2 border-double bg-zmrokMODALlighter border-zmrokNAVY-dark text-zmrokGRAY-dark hover:bg-zmrokGRAY-lighter rounded uppercase tracking-wide text-sm mt-2 mb-2">\n' +
  "                                            Hierarchy map\n" +
  "                                        </button>\n" +
  "                                    </div>\n" +
  "\n" +
  '                                    <div class="mt-1 text-center justify-center">\n' +
  "                                        <button onclick=\"openMap(event, 'MAP-INBOUND-OUTBOUND')\"\n" +
  '                                                class="font-modal-sans px-3 py-1 border-2 border-double bg-zmrokMODALlighter border-zmrokNAVY-dark text-zmrokGRAY-dark hover:bg-zmrokGRAY-lighter rounded uppercase tracking-wide text-sm mb-8">\n' +
  "                                            Connections map\n" +
  "                                        </button>\n" +
  "                                    </div>\n" +
  "\n" +
  "                                </div>\n" +
  "                            </div>\n" +
  "\n" +
  "                        </div>  \n" +
  "                    </div>\n" +
  "\n" +
  "\n" +
  "                    \n" +
  "\n" +
  "                    <!-- okno mapa koniec !-->\n" +
  '                    <!-- modal "link menu"  -->\n' +
  '                    <div class="hidden modal absolute inset-0 bg-gray-800 bg-opacity-60\n' +
  '                         flex justify-center items-center"\n' +
  '                         id="LinkWindowDiv">\n' +
  "                        <!-- modal -->\n" +
  "\n" +
  '                        <div class="bg-zmrokMODALlighter w-2/3 md:w-2/3 lg:w-2/5 py-2 px-3 rounded-xl shadow-xl z-10 opacity-95">\n' +
  '                            <div class="relative ramka border-zmrokMODAL-dark border-2 pb-4 m-0\n' +
  '                                 rounded-xl">\n' +
  "                                <!-- modal header -->\n" +
  "                                <!-- modal body -->\n" +
  '                                <div class="text-center font-modal-sans pt-3">\n' +
  "\n" +
  '                                    <h4 class="text-lg font-modal-sans\n' +
  '                                        text-gray-900 italic font-extrabold mt-6 pb-5 tracking-wide">LINK MENU</h4>   \n' +
  '                                    <div class="text-gray-700 mt-1 font-modal-sans">***</div>\n' +
  '                                    <h5 class="text-lg font-modal-sans mb-6">See your paths and links:</h5>\n' +
  "\n" +
  '                                    <div class="w-full grid grid-cols-12 gap-0" id="LinkInOutDivs">\n' +
  "                                         \n" +
  "                                    </div>\n" +
  "\n" +
  "\n" +
  "                                </div>\n" +
  "\n" +
  '                                <div class="p-3">\n' +
  "\n" +
  "\n" +
  '                                    <div class="link-title w-full font-modal-sans">\n' +
  "                                        <!-- 4E. MODAL – TREŚĆ  -->\n" +
  "\n" +
  "\n" +
  "                                        <!-- 4F. MODAL –  TYTUŁY LINKÓW  -->\n" +
  "\n" +
  "\n" +
  '                                        <ul id="<ul>_link-title">\n' +
  '                                            <!--  <li class="bg-gray-100">A.Link title 1  "title element" </li> -->\n' +
  '                                            <!--  <li class="bg-gray-300">B.Link title 1 "title element" </li> -->\n' +
  "                                        </ul>\n" +
  "                                    </div>\n" +
  "\n" +
  '                                    <div class="destination-title w-full flex-nowrap">\n' +
  '                                        <ul id="<ul>_destination-title">\n' +
  "                                            <!-- 4F. MODAL –  TYTUŁY SEGMENTÓW DOCELOWYCH  -->\n" +
  '                                            <!--<li class="bg-red-900">C.Destination title 1 "rev-title" </li> -->\n' +
  '                                            <!-- <li class="bg-gray-300">D.Destination title 2 "rev-title" </li> -->\n' +
  "                                        </ul>\n" +
  "                                    </div>\n" +
  "                                </div>\n" +
  "\n" +
  '                                <div class="absolute top-0 right-0 flex justify-end items-center w-100 p-3">\n' +
  "                                    <button\n" +
  '                                        class="rounded-full h-12 w-12 px-3 py-1 border-2 border-double bg-zmrokMODALlighter border-gray-800 text-gray-800 hover:bg-zmrokGRAY-lighter uppercase font-modal-sans text-xs close-modal"\n' +
  '                                        onclick="return HideLinkListWindow();">\n' +
  '                                        <svg onclick="return HideHistoryWindow();"\n' +
  '                                             class="close-modal cursor-pointer"\n' +
  '                                             fill="currentColor" viewBox="0 0 20 20">\n' +
  '                                        <path fill-rule="evenodd"\n' +
  '                                              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"\n' +
  '                                              clip-rule="evenodd">\n' +
  "\n" +
  "                                        </path>\n" +
  "                                        </svg>\n" +
  "                                    </button>\n" +
  "                                </div>\n" +
  "                            </div> \n" +
  "                        </div>\n" +
  "                    </div>\n" +
  "                </div>\n" +
  "            </div>\n" +
  "        </div>\n" +
  "\n" +
  "\n" +
  '        <div id="DEBUG_DIV">\n' +
  '            Debug info: <br> Fragment path: <a id="DEBUG_FRAGMENT_PATH">...</a>\n' +
  '            <br> HTML paths: <a id="DEBUG_FRAGMENT_PATHS"> </a> <br>\n' +
  '            Additional: <a id="DEBUG_OTHER"></a> <br>\n' +
  "\n" +
  '            <div id="file_name">autumnalwalkings.html</div>\n' +
  '            <div id="link_in">[LINK_IN]</div> \n' +
  '             <div id="link_out">[LINK_OUT]</div> \n' +
  "        </div>\n" +
  '<div class="breadcrumb-container box-content bg-transparent h-64 w-3/3 content-center m-auto relative flex-wrap mt-2 mb-0">\n' +
  "\n" +
  '               <div class="flex flex-wrap bred-panel font-mono text-sm text-gray-300 justify-end justify-items-end absolute bottom-2 right-2">\n' +
  '            <nav class="">\n' +
  "                <ul class=\"flex p-10 flex-wrap \" id='<ul>_of_breadcrumb'>\n" +
  "                   \n" +
  "                </ul>\n" +
  "            </nav>\n" +
  "        </div>\n" +
  "  </div>     <!--   breadcrumb container end   -->\n" +
  "\n" +
  "\n" +
  '        <script type="text/javascript" src="linki.js"></script>\n' +
  '        <script type="text/javascript" src="filename_to_fragmentname.js"></script>\n' +
  '        <script type="text/javascript" src="tree.js"></script>\n' +
  "\n" +
  '        <script type="text/javascript" src="inbound_outbound.js"></script>\n' +
  '        <script type="text/javascript" src="guards.js"></script>\n' +
  '        <script type="text/javascript" src="hrefs.js"></script>\n' +
  "\n" +
  '        <script type="text/javascript" src="main.js"></script>\n' +
  "\n" +
  '        <script src="https://d3js.org/d3.v4.min.js" type="text/javascript"></script>\n' +
  '        <script src="https://d3js.org/d3-selection-multi.v1.js"></script>\n' +
  '        <script type="text/javascript" src="InboundOutboundMap.js"></script>\n' +
  '        <script type="text/javascript" src="HierarchyMap.js"></script>\t\n' +
  '        <script type="text/javascript" src="MapWindow.js"></script>\t\n' +
  "\n" +
  "    </body>\n" +
  "\t\n" +
  "\n" +
  "</html>"
);


export default class TestPage extends Component {
  componentDidMount() { }

  render() {
    return (
      <Layout>
        <p> text </p>

        <button
          className="button normal"
          onClick={function () {
            let v = new FragmentWrapper(test_fragment);

            // TODO: remove any (assuming this file is actually used)
            const getLexiaDom = (w: any) => w.lexiaDom.innerHTML.toString();
            const originalHTML = getLexiaDom(v);
            const assertLexiaDom = (w: any) => {
              if (getLexiaDom(w) !== originalHTML) {
                console.error("Does not match!");
              }
              console.log(
                "defaultLinkDom =\n\t",
                w.defaultLinkDom ? w.defaultLinkDom.outerHTML : "-"
              );
            };

            assertLexiaDom(v);
            v.setDefaultLinkHref("someHref");
            assertLexiaDom(v);
            v.setDefaultLinkHref("changedAgain");
            assertLexiaDom(v);
          }}
        >
          Test frag
        </button>

        <div className="ui three buttons">
          <button className="ui active button">One</button>
          <button className="ui button">Two</button>
          <button className="ui button">Three</button>
        </div>

        <div className="ui compact menu">
          <a className="item">
            <i className="icon mail"></i> Messages
            <div className="left floating ui red label">22</div>
          </a>
          <a className="item">
            <i className="icon users"></i> Friends
            <div className="left floating ui teal label">22</div>
          </a>
        </div>
        <div className="ui compact menu">
          <a className="item">
            <i className="icon mail"></i> Messages
            <div className="left floating ui red label">22 Mails</div>
          </a>
          <a className="item">
            <i className="icon users"></i> Friends
            <div className="left floating ui teal label">22 Friends</div>
          </a>
        </div>

      </Layout>
    );
  }
}
